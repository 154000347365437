<template>
  <section>
    <article class="container">
      <main class="card table-list">
        <h3>
          {{ $t("searchResult") }}
        </h3>
        <ul class="layout-about-item-label">
          <li>
            <span>{{ $t("extrinsicHash") }}</span>
            <router-link class="text-blue" :to="'/extrinsic/'+hash">{{hash}}</router-link>
          </li>
          <li>
            <span>{{ $t("EVMHash") }}</span>
            <router-link class="text-blue" :to="'/evmTx/'+hash">{{hash}}</router-link>
          </li>
        </ul>
      </main>
    </article>
  </section>
</template>
<script>
export default {
  props:['hash']
}
</script>